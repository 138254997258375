<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="DISTRIBUTERS"
          :breadcrumb="[
            {
              label: 'Distributers',
            },
            { label: 'Distributers List' },
          ]"
        >
        </banner>

        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div class="card custom-card" style="height: 100%">
              <div class="card-body">

                <div class="row">
                  <div class="col text font">

                    <strong>Distributers</strong>
                  </div>
                  <div class="col text-end ">
                  
                  <button type="button" data-bs-toggle="modal" data-bs-target="#new-task-modal" title="Filter"
                      class="btn text-white btn-sm" style="background-color: #00364f"> <strong> <font-awesome-icon
                          icon="filter" /></strong></button>

                  </div>
                    <div class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1" style="margin-top:-5px">

                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>



                <div class="row">
                  <div class="d-md-none d-xl-none d-block col-3 text font">

                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
                    <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>

                    </select>
                  </div>
               
                </div>




                <!-- start list area  -->
                <div class="row">
        

<div class="col-xl-6">
  <h4><b style="color: #00364f; font-weight: bolder">Distributers</b></h4>
</div>
<div class="col-xl-6">
  <button
                      data-bs-toggle="modal"
                        data-bs-target="#new-task-modal"
                      title="Filter"
                        class="btn btn-sm text-white"
                        style="background-color: #00364f"
                      >
                        <strong> <font-awesome-icon icon="filter" /></strong>
                      </button>
</div>


                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th>+</th>
                          <th>Code</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>

                        <tbody>
                          <!-- v-for="(distributer, index) in allDistributers"
                                                        :key="index" -->

                          <tr
                            style="color: #00364f"
                            v-for="(distributer, index) in allDistributers"
                            :key="index"
                          >
                            <td>
                              <img
                                style="width: 30px; height: 30px"
                                :src="profilePhotoFinder(distributer)"
                                alt=""
                              />
                            </td>

                            <td class="text-truncate" v-if="distributer">
                              {{ distributer.code }}
                            </td>
                            <td class="text-truncate" v-if="distributer">
                              {{ distributer.name }}
                            </td>
                            <td class="text-truncate" v-if="distributer">
                              {{ distributer.email }}
                            </td>
                            <td class="text-truncate" v-if="distributer">
                              {{ distributer.phone }}
                            </td>

                            <td>
                              <p
                                v-if="distributer.loginStatus == 'true'"
                                class="text-white btn btn-sm"
                                style="
                                  background-color: green;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <strong>Active</strong>
                              </p>
                              <p
                                v-else
                                class="text-white btn btn-sm"
                                style="
                                  background-color: red;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <strong>InActive</strong>
                              </p>
                            </td>
                            <td>
                              <router-link
                                :to="`/admin/distributer-profile/${distributer.id}`"
                                class="btn btns btn-sm text-white"
                                style="
                                  padding: 5px 4.5px 5px 4.5px;
                                  background-color: #00364f;
                                "
                              >
                                <font-awesome-icon icon="eye" />
                              </router-link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>


   <!-- Right Sidebar starts -->
   <div class="modal modal-slide-in sidebar-todo-modal fade" id="new-task-modal">
    <div class="modal-dialog sidebar-sm">
      <div class="modal-content p-0">
        <form
          id="form-modal-todo"
          class="todo-modal needs-validation"
          novalidate
          onsubmit="return false"
        >
          <div
            class="modal-header align-items-center mb-1"
            style="background-color: #00364f"
          >
            <h5 class="modal-title text-white"><strong>Project Filter</strong></h5>
            <div
              class="todo-item-action d-flex align-items-center justify-content-between ms-auto text-white"
            >
              <span class="todo-item-favorite cursor-pointer me-75 text-white"
                ><i data-feather="star" class="font-medium-2"></i
              ></span>
              <i
                data-feather="x"
                class="cursor-pointer"
                data-bs-dismiss="modal"
                stroke-width="3"
                >X</i
              >
            </div>
          </div>
          <div class="modal-body flex-grow-1 pb-sm-0 pb-3">



            <ul class="list-group list-group-flush">
  <li class="list-group-item">
    <div class="form-check">
  <input               @click="valueNullFilter()" v-model="filterType" value="code" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
    Code
  </label>
</div>
  </li>
  <li class="list-group-item">
    <div class="form-check">
  <input               @click="valueNullFilter()" v-model="filterType" value="orgnization" class="form-check-input" type="radio" name="flexRadioDefault" id="orgnization">
  <label class="form-check-label" for="orgnization">
    
    Orgnization
  </label>
</div>
</li>
  <!--   <li class="list-group-item">
    <div class="form-check">
  <input v-model="filterType" value="dueDate" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
  Due Date
  </label>
</div>
</li> -->
<!-- <li class="list-group-item">
    <div class="form-check">
  <input v-model="filterType" value="completionDate" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
  Completion Date
  </label>
</div>
  </li> -->
  <li class="list-group-item"><div class="form-check">
  <input               @click="valueNullFilter()" v-model="filterType" value="name" class="form-check-input" type="radio" name="flexRadioDefault" id="name">
  <label class="form-check-label" for="name">
    Name
  </label>
</div></li>

<li class="list-group-item"><div class="form-check">
  <input               @click="valueNullFilter()" v-model="filterType" value="email" class="form-check-input"  type="radio" name="flexRadioDefault" id="email">
  <label class="form-check-label" for="email">
    email
  </label>
</div></li>

<li class="list-group-item"><div class="form-check">
  <input               @click="valueNullFilter()" v-model="filterType" value="phone" class="form-check-input"  type="radio" name="flexRadioDefault" id="phone">
  <label class="form-check-label" for="phone">
    phone
  </label>
</div></li>
<li class="list-group-item">
    <div class="form-check">
  <input               @click="valueNullFilter()" v-model="filterType" value="all" class="form-check-input" type="radio" name="flexRadioDefault" id="all">
  <label class="form-check-label" for="all">
    All
  </label>
</div>
  </li>
</ul>


<div class="pt-1 pb-1">
  <!-- <label for="exampleFormControlInput1" class="form-label">Email address</label> -->
 <!-- <input type="date" v-if="filterType == 'date' || filterType == 'dueDate' || filterType == 'completionDate'" class="form-control datepicker" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value"> -->
 <input :type="filterType == 'phone' ? 'number' : 'text'" v-if="filterType != 'all'" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value">
 <!-- <input type="text" v-if="filterType == 'client_id'" @change="findRecordeByPanId" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value"> -->
 







</div>

<button  @click="loadDistributers()" type="button" class="btn btn-success btn-sm">Success</button>



           
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Sidebar ends -->

</template>

//
<script>
import Banner from "../../../components/admin/comman/Banner.vue";

import Spinner from "../../../components/admin/comman/Spinner.vue";
// import Form from "vform";
// import jQuery from "jquery";
// let $ = jQuery
export default {
  name: "Distributers",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      loading: false,
      allDistributers: [],
    };
  },
  methods: {
    valueNullFilter(){
  this.filterValue = "";
},
    profilePhotoFinder(distributer) {
      var photo;
      if (distributer.photo != null) {
        photo =
          this.$store.state.imgUrl +
          "/distributer/" +
          distributer.id +
          "/thumbs/" +
          distributer.photo;
        return photo;
      } else {
        photo = this.$store.state.placeholderImg;
        return photo;
      }
    },
    loadDistributers() {
      
      this.loading = true
      this.$axios
        .get(`admin/distributer?master_distributer_id=${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.allDistributers = res.data.data.data;
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadDistributers();
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
</style>
